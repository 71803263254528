import React from 'react';
import { StyledSectionServices } from "./SectionServices.style";
import { Col, Container, Row } from "react-bootstrap";

const services = [{
  key: 'preventie',
  icon: 'icon-tooth-cleaning',
  title: 'Preventie',
  description: 'Preventia este cheia unei sanatati orale de lunga durata. Cu cat problemele dentare sunt descoperite mai devreme, cu atat scad sansele ca acestea sa scape de sub control provocand dureri. Puteti obtine acest lucru prin igienizare si control periodic.'
}, {
  key: 'tratamente-canal',
  icon: 'icon-dental-care-3',
  title: 'Tratamente de canal',
  description: 'Uneori tratamentele de canal sunt singura optiune de a salva un dinte cu o carie avansata sau care prezinta o infectie la nivelul radacinii.'
}, {
  key: 'extractii-dentare',
  icon: 'icon-tooth-6',
  title: 'Extractii dentare',
  description: 'Din pacate uneori se ajunge extractia dintilor ce nu mai au nicio sansa de salvare. Incercam mereu prin toate mijloacele posibile sa facem despartirea dintre dumneavoastra si dinti cat mai putin dureroasa.'
}, {
  key: 'pedodontie',
  icon: 'icon-baby',
  title: 'Treatamente pentru copii',
  description: 'Indiferent de varsta, dintii joaca un rol important in diferite functii ale organismului. Cu toate ca dentitia celor mici se schimba, pierderea precoce a dintilor temporari poate avea efecte negative asupra dezvoltarii copiilor.'
}, {
  key: 'restaurari-protetice',
  icon: 'icon-dental-1',
  title: 'Restaurari protetice',
  description: 'Din fericire exista metode de a inlocui dintii pierduti de-a lungul vietii sau sa reconstruim dintii inca prezenti dar care nu mai sunt integri. Acest lucru se poate realiza fie prin lucrari fixe, mobile sau mobilizabile in functie de situatie.'
}, {
  key: 'obturatii',
  icon: 'icon-tooth-1',
  title: 'Obturatii fizionomice',
  description: 'Cariile sunt cele mai des intalnite probleme. In functie de intinderea si profunzimea acestora, dupa curatarea dintelui acesta poate fi reconstruit cu ajutorul unor plombe de culoarea dintelui ce pot trece neobservate.'
}, {
  key: 'estetica-dentara',
  icon: 'icon-smile',
  title: 'Estetica dentara',
  description: 'Prin diverse proceduri cosmetice, fie ca este vorba de albire dentara, fie ca este vorba doar de o lustruire a dintilor prin periaj dentar preofesional, putem reda stralucirea zambetului dumneavoastra. De asemenea cu ajutorul fatetelor dentare se poate corecta printr-o metoda minim invaziva forma dintilor sau diferite defecte ale dintilor care va dau batai de cap.'
}, {
  key: 'protectie-dentara',
  icon: 'icon-tooth-2',
  title: 'Protectie dentara',
  description: 'Bruxismul nocturn sau scrasnitul dintilor poate duce la pierderea substantei dentare prin tocirea dintilor. Acestia insa pot fi protejati cu ajutorul unor gutiere ce se interpun intre suprafetele dintilor antagonisti.'
},
];

const SectionServices = () => (
  <StyledSectionServices id="services-section">
    <Container>
      <Row className="mb-5">
        <Col>
          <div className="block-heading-1">
            <span>O multime de optiuni</span>
            <h2>Servicii stomatologice</h2>
          </div>
        </Col>
      </Row>
      <div className="services-wrapper">
        {services.map(service => (
          <div key={service.key} lg={4} md={6} className="service-column mb-5">
            <div className="block-feature-1 pb-1 h-100">
            <span className="icon">
              <span className={service.icon}/>
            </span>
              <h2 className="text-dark">{service.title}</h2>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  </StyledSectionServices>
);

SectionServices.propTypes = {};

SectionServices.defaultProps = {};

export default SectionServices;
