import React from 'react';
import { StyledComingSoon, StyledText } from "./ComingSoon.style";
import { ComingSoonStyledLogo } from "./ComingSoon.style";

const ComingSoon = () => (
  <>
    <StyledComingSoon/>
    <StyledText className="d-flex justify-content-center align-items-center coming-soon-logo-container">
      <ComingSoonStyledLogo className="text-center py-5 coming-soon-logo">
        <img className="stoma-logo mt-3" src="images/new.png"/>
        <h1 className="pt-2">Pagina este aproape gata... </h1>
      </ComingSoonStyledLogo>
    </StyledText>
  </>
);

ComingSoon.propTypes = {};

ComingSoon.defaultProps = {};

export default ComingSoon;
