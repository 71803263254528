import React from 'react';
import { StyledFooter } from "./Footer.style";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => (
  <StyledFooter>
    <Container>
      <Row className="justify-content-center">
        <Col sm={12} md={3} lg={4} className="align-self-center text-center">
          <img
            className="stoma-logo-footer"
            src="images/new3.png"
            alt="Logo"
          />
        </Col>
        <Col sm={12} md={3} lg={4}>
          <h5>Contact</h5>
          <div className="d-flex contact-data mb-1">
            <i className="icon-location pr-2"/>
            <div>
              <p className="mb-0">Str General Stefan Falcoianu, 1</p>
              <p className="mb-0">Centru Medical Brazda</p>
              <p className="mb-0">Parter, Cabinet 8</p>
            </div>
          </div>
          <div className="d-flex contact-data mb-1">
            <i className="icon-phone pr-2"/>
            <div>
              <p className="mb-0">Telefon: 0750494849</p>
            </div>
          </div>
          <div className="d-flex contact-data mb-1">
            <i className="icon-envelop pr-2"/>
            <div>
              <p className="mb-0">contact@drdianaurdiniceanu.ro</p>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6} lg={4}>
          <h5>Program</h5>
          <Row className="business-hours">
            <Col>
              <Row>
                <Col>Luni</Col>
                <Col>10:00 - 14:00</Col>
              </Row>
              <Row>
                <Col>Marti</Col>
                <Col>14:00 - 18:00</Col>
              </Row>
              <Row>
                <Col>Miercuri</Col>
                <Col>10:00 - 14:00</Col>
              </Row>
              <Row>
                <Col>Joi</Col>
                <Col>14:00 - 18:00</Col>
              </Row>
              <Row>
                <Col>Vineri</Col>
                <Col>10:00 - 14:00</Col>
              </Row>
              <Row>
                <Col>Sambata</Col>
                <Col>Inchis</Col>
              </Row>
              <Row>
                <Col>Duminica</Col>
                <Col>Inchis</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <iframe
        id="map-container"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2853.9534018917448!2d23.797743914881522!3d44.33146221632542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4752d76eff87f69b%3A0x28e0695bad47aab6!2sStrada%20General%20%C8%98tefan%20F%C4%83lcoianu%201%2C%20Craiova!5e0!3m2!1sen!2sro!4v1612642005524!5m2!1sen!2sro"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />
      <div className="social text-center pt-5 pb-5">
        <a href="https://facebook.com/drdianaurdiniceanu" target="_blank" rel="noreferrer">
          <i className="icon-facebook"/>
        </a>
      </div>
      <div className="text-center text-white">
        Icons made by <a href="https://www.flaticon.com/authors/monkik" title="monkik">monkik</a> from <a
        href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    </Container>
  </StyledFooter>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
