import styled from 'styled-components';

export const StyledComingSoon = styled.div`
  height: 100vh;
  background-image: url('images/img-video-1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom right;
  opacity: 0.3;
  
  .stoma-logo {
    max-height: 77px;
  }
  
  .row {
    height: 100vh;
  }
  
  p {
      font-size: 18px;
      color: #999999;
      max-width: 400px;
  }
`;

export const ComingSoonStyledLogo = styled.div`
  box-shadow: 0 8px 20px -4px #95abbb;
  //background-color: rgba(255, 255, 255, 0.1);
  padding-left: 5rem;
  padding-right: 5rem;
  .stoma-logo {
    max-height: 37px;
  }
  
  h1 {
    color: #FFFFFF;
    text-shadow: 0 8px 20px #95abbb;
  }
`;

export const StyledText = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
